'use strict';

app.directive("quartoComboBoxOU", function () {
    return {
        restrict: "E",
        replace: true,
        scope: {
            customOptions: '='
        },
        template: function (tElement, tAttrs) {
            return '<div><select kendo-drop-down-list="comboBoxOUQCFieldInspectionCat" style="width:380px;float:right;" k-options="ouOptions" ng-disabled="customOptions.isEdit" />' +
                    '<span class="control-label">OU:</span></div>';
        },
        controller: function ($rootScope, $scope, authService, controlService) {
            $scope.authentication = authService.authentication;
            //$scope.ouDataSource = $rootScope.GetDataSource("ou");
            // modified on 03/08/2022 -> filter for OE only
            $scope.ouDataSource = $rootScope.GetDataSource("ou", void 0, {
                logic: "and",
                filters: [{
                    field: "OperationTypeCode",
                    operator: "eq",
                    value: "OE"
                }]
            });

            $scope.customOptions = $scope.customOptions || {};
            $scope.customOptions.updateValue = function (value) {
                $scope.comboBoxOUQCFieldInspectionCat.value(value);
            }
            $scope.customOptions.onValueChanged = $scope.customOptions.onValueChanged ||  function (dataItem) {
                //console.log("dataItem", dataItem);
            }

            //duno why it is called twice!
            $scope.prevOUKey = -1;

            $scope.ouOptions = {
                autoBind: true,
                suggest: true,
                dataSource: $scope.ouDataSource,
                dataTextField: 'OUCodeOUDesc',
                dataValueField: 'OUKey',
                change: function (e) {
                    if (typeof e.sender.dataItem() === 'undefined') {
                        e.sender.value(null);
                    }
                    else if (e.sender.dataItem().OUKey == -1) {
                        e.sender.value(null);
                    }
                    else {
                        var dataItem = e.sender.dataItem();
                        var ouKey = dataItem.OUKey;
                        if ($scope.prevOUKey == ouKey)
                            return;
                        $scope.prevOUKey = ouKey;
                        $scope.customOptions.onValueChanged(dataItem);
                    }
                },
                select: function (e) {
                    if (controlService.disableInactiveItem(e, this)) return;

                    if (e.item != null) {
                        var dataItem = this.dataItem(e.item.index());
                        var ouKey = dataItem.OUKey;
                        if ($scope.prevOUKey == ouKey)
                            return;
                        $scope.prevOUKey = ouKey;
                        $scope.customOptions.onValueChanged(dataItem);
                    }
                    else {

                    }
                },
                template: controlService.comboboxItemListDisabledTemplate("OUCodeOUDesc"),
                dataBound: function (e) {
                    this.value($scope.authentication.defaultOUKey);
                }
            }
        }
    };
})

app.directive("dbdCustomCalendar", function ($filter) {
    return {
        restrict: "E",
        replace: true,
        scope: {
            customDate: "=",
            mode: "=",
        },
        template: function(tElement, tAttrs){
            return "<div><time ng-show='customDate && !isDateMin(customDate)' class='customWidgetCalendar'>\
                    <strong class='monthLabel'>{{customDate | date: 'MMM'}}</strong>\
                    <span class='dateLabel'>{{customDate | date: 'dd'}}</span>\
                    </time>\
                    <div ng-show='mode == \"BYMTD\"' style='width:45px;height:45px;line-height:45px;font-size:15px;position:relative;top:-12px;float:left;border-radius:0.6em;box-shadow:1px 1px 1px 0 #bdbdbd;overflow:hidden;background-color:#099F94;color:#ffffff'>\
                           <strong> MTD </strong>\
                    </div>\
                    <div ng-show='mode == \"BYYTD\"' style='width:45px;height:45px;line-height:45px;font-size:15px;position:relative;top:-12px;float:left;border-radius:0.6em;box-shadow:1px 1px 1px 0 #bdbdbd;overflow:hidden;background-color:#EE5A2A;color:#ffffff'>\
                           <strong> YTD </strong>\
                    </div>\
                    </div>";
        },
        controller: function ($scope) {
            $scope.isDateMin = function (date) {
                if (!date)
                    return false;
                if (!date.getFullYear)
                    return false;
                return date.getFullYear() == 1;
            }
        }
    }
})

app.directive("dbdErrorMessage", function () {
    return {
        restrict: "E",
        replace: true,
        scope: {
            isShow: "="
        },
        template: function (tElement, tAttrs) {
            return '<div ng-show="isShow">\
                        <span style="font-size:12px">No data. Please try again.</span>\
                    </div>';
        }
    }
})

app.directive("lmDBoard", function () {
    return {
        restrict: "AE",
        template: "<div kendo-chart='{{dboardId}}' id='{{dboardId}}' k-options='lmOptions' k-on-data-bound='gridDataBound(kendoEvent)'></div>",
        scope: {
            dboardId: "@",
            lmOptions: "=",
            //lmTitle: "@",
            //lmType: "@",
            //lmField: "@",
            //lmValueTitle: "@",
        },
        controller: function ($scope) {
            //$scope.year = $filter('date')(Date.now(), 'yyyy');
            //$scope.month = $filter('date')(Date.now(), 'MMM');
            //$scope.symbol = "RM";

            $scope.lmOptions = {
                title: { text: lmTitle },
                legend: {
                    position: "top"
                },
                series: [{ type: 'column', field: 'TotalPOAmt' }],
                valueAxis: {
                    title: { text: "Amount (" + $scope.symbol + ")" },
                    labels: {
                        format: '{0:n2}',
                        skip: 2,
                        step: 2
                    }
                },
                categoryAxis: {
                    field: "Month",
                },
                tooltip: {
                    visible: true,
                    //font: "20px sans-serif",
                    format: "{0:n2}"
                },
                dataSource: $scope.monthlypoDS
            };

            //$scope.test = "TEST";
            $scope.gridDataBound = function (e) {
                //console.log(e);
            };

        }
    };
});